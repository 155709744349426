<template>
    <div>
        <CompTable ref="comp_table" title="商品管理" isSelector :keys="{ page: 'currPage', size: 'size' }" :columns="columns" :table-api="tableApi" :dataBefore="onDataBefore">
            <template #operate>
                <Dropdown transfer @on-click="onBatchChange">
                    <Button>
                        批量处理
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <template #list>
                        <DropdownMenu>
                            <DropdownItem name="enable">批量上架</DropdownItem>
                            <DropdownItem name="disable">批量下架</DropdownItem>
                        </DropdownMenu>
                    </template>
                </Dropdown>
                <!-- 新增按钮 -->
                <Button type="info" @click="$refs.data_form.open()">新增</Button>
            </template>
            <template v-slot:search="data">
                <Select v-model="data.search.isOnSale" filterable style="width:200px" transfer placeholder="上架/下架">
                    <Option value="1">上架</Option>
                    <Option value="0">下架</Option>
                </Select>
                <Select v-model="data.search.supplierId" filterable style="width:200px" transfer placeholder="供应商">
                    <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                </Select>
                <Select v-model="data.search.promotionType" multiple filterable style="width:200px" transfer placeholder="促销类型">
                    <Option value="isHot">热销</Option>
                    <Option value="isBest">推荐</Option>
                </Select>
            </template>
        </CompTable>

        <DataForm :supplierList="suppliers" ref="data_form" @on-reset="$refs.comp_table.refresh()"></DataForm>
    </div>
</template>

<script>
import DataForm from "./form.vue"
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"

export default {
    components: { CompTable, DataForm },

    data() {
        return {
            // 供应商列表
            suppliers: null,
            // 表格接口
            tableApi: null,

            columns: [
                {
                    title: "商品ID",
                    key: "goodsId",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "名称",
                    key: "goodsName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "排序",
                    minWidth: 130,
                    align: "center",
                    render: (h, params) => {
                        return h("InputNumber", {
                            props: {
                                value: params.row.sortOrder,
                                min: 0,
                                max: 9999,
                            },
                            on: {
                                "on-change": v => {
                                    if (this.changeSortOrderTime) {
                                        clearTimeout(this.changeSortOrderTime)
                                    }

                                    this.changeSortOrderTime = setTimeout(() => {
                                        Request.post(
                                            "/gateway/apps/o2o/api/o2o/goods/updateGoodsAttr",
                                            {
                                                goodsId: params.row.goodsId,
                                                sortOrder: v,
                                            },
                                            { json: true }
                                        ).then(() => {
                                            this.$Message.success({
                                                content: "修改成功",
                                                background: true,
                                            })
                                        })
                                    }, 2000)
                                },
                            },
                        })
                    },
                },
                {
                    title: "类型",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, { goods: "实物商品", service: "预约到店服务", visit_service: "预约上门服务", coupon: "到店核销券" }[params.row.serviceType] || "未知")
                    },
                },
                {
                    title: "库存",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, ["goods", "coupon"].includes(params.row.serviceType) ? params.row.goodsNumber : "-")
                    },
                },
                {
                    title: "单价",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.shopPrice ? "￥" + params.row.shopPrice : "-")
                    },
                },
                {
                    title: "促销",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        const req = data => {
                            Request.post(
                                "/gateway/apps/o2o/api/o2o/goods/updateGoodsAttr",
                                {
                                    goodsId: params.row.goodsId,
                                    ...data,
                                },
                                { json: true }
                            ).then(() => {
                                this.$Message.success({
                                    content: "修改成功",
                                    background: true,
                                })
                            })
                        }

                        return h("div", [
                            h(
                                "Checkbox",
                                {
                                    props: {
                                        value: params.row.isHot === 1,
                                    },
                                    on: {
                                        "on-change": v => {
                                            req({
                                                isHot: v ? 1 : 0,
                                            })
                                        },
                                    },
                                },
                                "热销"
                            ),
                            h(
                                "Checkbox",
                                {
                                    props: {
                                        value: params.row.isBest === 1,
                                    },
                                    on: {
                                        "on-change": v => {
                                            req({
                                                isBest: v ? 1 : 0,
                                            })
                                        },
                                    },
                                },
                                "推荐"
                            ),
                        ])
                    },
                },
                {
                    title: "状态",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.isOnSale === 1 ? "#67c23a" : "#f56c6c",
                                },
                            },
                            params.row.isOnSale === 1 ? "已上架" : "已下架"
                        )
                    },
                },
                {
                    float: "right",
                    title: "操作",
                    width: 280,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.data_form.open(params.row.goodsId)
                                        },
                                    },
                                },
                                "编辑"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "提示",
                                                content: "当前删除操作将不可恢复，请谨慎操作，是否继续？",
                                                onOk: () => {
                                                    this.$post(`/gateway/apps/o2o/api/o2o/goods/deleteGoods?goodsId=${params.row.goodsId}&custGlobalId=${parent.vue.loginInfo.userinfo.custGlobalId}&oemCode=${parent.vue.oemInfo.oemCode}`).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            this.$refs.comp_table.refresh()
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc || "删除失败",
                                                                background: true,
                                                            })
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: params.row.isOnSale === 1 ? "warning" : "success",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            const s = params.row.isOnSale

                                            this.$post(`/gateway/apps/o2o/api/o2o/goods/${s === 1 ? "modifyGoodsUnSale" : "modifyGoodsOnSale"}`, {
                                                goodsId: params.row.goodsId,
                                                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                                oemCode: parent.vue.oemInfo.oemCode,
                                            }).then(res => {
                                                if (res.code == 200) {
                                                    this.$Message.success(`${s === 1 ? "下架" : "上架"}成功`)
                                                    params.row.isOnSale = s === 1 ? 0 : 1
                                                } else {
                                                    this.$Message.error(res.desc || `${s === 1 ? "下架" : "上架"}失败`)
                                                }
                                            })
                                        },
                                    },
                                },
                                params.row.isOnSale === 1 ? "下架" : "上架"
                            ),

                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            Request.get("/gateway/apps/o2o/api/o2o/goods/getGoodsDetail", {
                                                goodsId: params.row.goodsId,
                                            }).then(res => {
                                                // 置空 ID
                                                res.goodsId = null
                                                // 打开表单
                                                this.$refs.data_form.open(null, res)
                                            })
                                        },
                                    },
                                },
                                "复制"
                            ),
                        ])
                    },
                },
            ],
        }
    },

    mounted() {
        this.getSupplierList()
    },

    methods: {
        getSupplierList() {
            this.$get("/gateway/apps/o2o/api/o2o/user/selectUserHasSupplier", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) {
                    const data = res.dataList
                    // 供应商列表
                    this.suppliers = data

                    if (data && data.length > 0) {
                        this.tableApi = `/gateway/apps/o2o/api/o2o/goods/getGoodsBySupplierId?oemCode=${parent.vue.oemInfo.oemCode}&supplierId=${data[0].supplierId}`
                    } else {
                        this.$Modal.warning({ title: "温馨提示", content: "当前账号没有绑定供应商，请先前往绑定！" })
                    }
                }
            })
        },

        onDataBefore(data) {
            if (data.promotionType?.length > 0) {
                data.promotionType.includes("isHot") && (data.isHot = 1)
                data.promotionType.includes("isBest") && (data.isBest = 1)
                delete data.promotionType
            }
        },

        /**
         * 批量修改
         */
        onBatchChange(type) {
            console.log(type)
            const s = this.$refs.comp_table.getSelection()

            if (!s || s.length === 0) {
                return this.$Message.warning("请至少选择一项商品")
            }

            Request.post(
                "/gateway/apps/o2o/api/o2o/goods/" + (type === "enable" ? "batchEnable" : "batchDisable"),
                {
                    goodsIds: s.map(v => v.goodsId),
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("修改成功")

                s.forEach(v => {
                    v.isOnSale = type === "enable" ? 1 : 0
                })

                /**
                 * 清空勾选
                 */
                this.$refs.comp_table.clearSelecteds()
            })
        },
    },
}
</script>
<style lang="less"></style>
